<template>
  <VtPage>
    <VtContainer>
      <div class="flex justify-between items-center px-4 py-2.5 gap-4">
        <NuxtLink
          :to="{ name: 'index' }"
          class="flex flex-col"
          :aria-label="t('brand.name')"
        >
          <img
            src="/brand.svg"
            :alt="t('brand.name')"
            class="w-36 dark:hidden h-5"
          />
          <img
            src="/brand-dark.svg"
            :alt="t('brand.name')"
            class="w-36 hidden dark:block h-5"
          />
          <span
            class="text-xs text-neutral-600 dark:text-neutral-400 leading-snug"
            translate="no"
          >
            {{ t('brand.appName') }}
          </span>
        </NuxtLink>
        <VtButton
          intent="tertiary"
          display="icon-only"
          :text="colorModeDisplay.text"
          :icon="colorModeDisplay.icon"
          @click="handleColorModeClick"
        />
      </div>
    </VtContainer>
    <NuxtPage />
  </VtPage>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NuxtLink, NuxtPage } from '#components'
import { useI18n } from '#i18n'

import { VtButton } from '@visiontree/vue-ui'

import { VtContainer, VtPage } from '@/components/vue-ui'
import { useColorMode } from '@/composables/useColorMode'

const colorMode = useColorMode()
const { t } = useI18n()

const colorModeDisplay = computed<{
  text: string
  icon: 'sun-bright' | 'moon'
}>(() => {
  if (colorMode.isDark.value) {
    return {
      text: t('buttons.lightMode'),
      icon: 'sun-bright',
    }
  }

  return {
    text: t('buttons.darkMode'),
    icon: 'moon',
  }
})

const handleColorModeClick = () => {
  colorMode.toggle()
}
</script>
